<template>
  <div class="vg_wrapper">
    <div class="flexV">
      <el-button size="mini" type="danger" @click="setSaveDirectory">设置项目JSON目录</el-button>
      <el-button size="mini" type="primary" @click="setForm">formDesign同步formBuild数据</el-button>
      <el-button size="mini" type="primary" @click="saveFile">保存文件</el-button>
      <el-input v-model="jsonFileName" placeholder="请先输入文件名在读取" size="mini" style="margin-left: 10px; width: 200px"></el-input>
      <el-button size="mini" type="primary" @click="readFile">读取文件</el-button>
    </div>

    <el-collapse v-model="activeNames">
      <el-collapse-item name="1" title="formDesign">
        <ng-form-design ref="formDesign" />
      </el-collapse-item>
      <el-collapse-item name="2" title="formBuild">
        <ng-form-build ref="formBuild" :formTemplate="form" :preview="true" />
      </el-collapse-item>
    </el-collapse>
  </div>
</template>

<script>
export default {
  name: 'About',
  components: {},
  data() {
    return {
      startIn: null,
      activeNames: ['1'],
      form: {},
      jsonFileName: ''
    };
  },
  methods: {
    setForm() {
      this.form = this.$refs.formDesign.getModel();
    },
    async setSaveDirectory() {
      this.startIn = await window.showDirectoryPicker();
    },
    async saveFile() {
      if (typeof window.showSaveFilePicker !== 'function') {
        return console.error('当前浏览器不支持showSaveFilePicker API');
      }
      try {
        const handle = await window.showSaveFilePicker({
          suggestedName: '.json',
          accept: { 'application/json': ['.json'] },
          startIn: this.startIn
        });
        const writable = await handle.createWritable(); // 创建一个可写的Stream
        await writable.write(new Blob([JSON.stringify(this.form, null, 2)], { type: 'application/json' }));
        await writable.close();
        console.log('文件已成功保存！');
      } catch (err) {
        console.error('保存文件时发生错误:', err);
      }
    },
    async readFile() {
      const fileHandle = await this.startIn.getFileHandle(this.jsonFileName + '.json');
      const file = await fileHandle.getFile();
      const content = await file.text();
      this.form = JSON.parse(content);
      this.$refs.formDesign.initModel(this.form);
    }
  }
};
</script>
